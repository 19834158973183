var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitInformation()}}},[_c('PageTitle',{attrs:{"items":{
          title: _vm.title,
          subTitle: _vm.subTitle,
          back: {
            action: function () {
              _vm.$router.push({
                name: 'InternshipPostList'
              })
            }
          }
        }}}),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('internship-basic-information',{attrs:{"basicInformation":_vm.basicInformation,"headings":_vm.headings}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-card',{staticClass:"text-center pt-14",attrs:{"height":"264px"}},[_c('div',{staticClass:"button-width mx-auto"},[_c('div',{staticClass:"btn-container",class:{ 'px-2': _vm.$vuetify.breakpoint.smAndDown }},[_c('v-btn',{attrs:{"block":"","outlined":"","type":"button","color":"#13ABA3"},on:{"click":function($event){return _vm.openDraftPopup()}}},[_vm._v("下書き保存")]),_c('br'),_c('v-btn',{staticClass:"mt-6",attrs:{"block":"","outlined":"","type":"button","color":"#13ABA3"},on:{"click":function($event){return _vm.preview()}}},[_vm._v("プレビュー")]),_c('br'),_c('v-btn',{staticClass:"white--text mt-6 button-width",attrs:{"block":"","type":"submit","color":"#13ABA3"}},[_vm._v("公開")])],1)])]),_c('ImageUpload',{attrs:{"data":_vm.imageDetails}})],1)],1)],1)],1),_c('SimpleModel',{attrs:{"text":"この求人を下書きとして保存しますか？","dialog":_vm.dialog.saveAsDraft},on:{"submitSuccess":function($event){return _vm.saveAsDraft()},"closeModel":function($event){_vm.dialog.saveAsDraft = false}}}),_c('InterPreviewModel',{attrs:{"dialog":_vm.dialog.preview},on:{"submitSuccess":function($event){return _vm.preview()},"closeModel":function($event){_vm.dialog.preview = false}}}),_c('SuccessModel',{attrs:{"text":_vm.alertText,"buttonText":"一覧へ戻る","routeName":_vm.routeName,"dialog":_vm.successDialog},on:{"closeModel":function($event){_vm.successDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }